import React, { useState } from 'react'
import './Reachus.css'
import Zoom from 'react-reveal/Zoom';
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import img2 from '../assets/Director2.jpeg'
import img1 from '../assets/istockphoto-1153130142-612x612.jpg'
import {AiOutlineUser} from 'react-icons/ai'
import {FiPhone} from 'react-icons/fi'
import{GrMailOption} from 'react-icons/gr'
import {MdOutlineMessage} from 'react-icons/md'
import{IoLocationOutline} from 'react-icons/io5'
export default function Reachus() {
    const [reachus, setReachUs] = useState({
        username:"",
        email:"",
        message:"",
    })

const handleChange = (event) =>{
    setReachUs({...reachus, [event.target.name]: event.target.value})
}


  return (
     <>
  <div className='borderreach'><Zoom>
<h1 className='conthead'> <strong>Contact us</strong></h1></Zoom>
  </div>

    <div className='reachUs'>
     <div className='contlorem'>
        <h1 className='belowdirectordetails'><strong>Contact Details</strong></h1>
<Zoom>
{/* <div className='directordetails'> */}
<p className='directordetails'>Dew Care Biotech is a highly reputed pharmaceutical company in North India. Pioneered from 2010, it started operations under the guidance of <strong>Mr. Vikas Chaudhary</strong> Managing Director.</p>
<p className='directordetails'><strong>Mr. Vikas Chaudhary</strong> established this company after gaining 10 years of experience from top pharma companies of the country namely Mankind, Swiss Medicare Pvt Ltd.</p>
{/* </div> */}
<p className='belowdirectordetails'>If you would like to connect with us, want to work with us? Looking for collaboration?  Please reach out to us on below contact details, we would be happy to work with you.</p>
</Zoom>
{/* <p>Let us help you know</p> */}
<p className='belowdirectordetails'>Please contact us on below!</p>
<Zoom>
    <p className='belowdirectordetails'><strong> Vikas Choudhary</strong></p>
</Zoom>
<Zoom>
    <p className='belowdirectordetails'><strong><IoLocationOutline/>DEW Care Biotech pvt ltd, 46/2, Azad Nagar East, Delhi - 110051</strong></p>
</Zoom>

<Zoom >
    <p className='belowdirectordetails'><strong><FiPhone/> + 91 9891027503</strong></p>
</Zoom>
<Zoom >
    {/* <p><strong><FiPhone/>  +91 9412336529</strong></p> */}
    </Zoom>
<p className='belowdirectordetails'> <GrMailOption/>dewcarebiotech@gmail.com</p>



</div>
    <form onSubmit={(event) =>{ console.log(reachus); event.preventDefault()} }> 
    <Flip top bottom>   <div className='formControl'>
        
      {/* <div className='user'> <AiOutlineUser/>   <input type='text' name="username" placeholder='Enter your name' onChange={(e) =>handleChange(e)}/></div>
    <div className='user'> <FiPhone/>  <input type='text' name="number" placeholder='Number' onChange={(e) =>handleChange(e)}/></div> 
    <div className='user'>  <GrMailOption/>  <input type="text" name="email" placeholder='Enter your email' onChange={handleChange} /></div>
    <div className='user'> <MdOutlineMessage/>   <textarea className='textarea'  name="message" placeholder='Enter your message' onChange={handleChange}/> </div>    
    <div className='button'>    <button type='submit'><strong>Submit</strong></button>  </div> */}
        <div>
            <img src={img2} alt="" width="100%"/>
            <h4 className='namemargin'>Vikas Choudhary</h4> 
            <h5 className='namemargin'>Director</h5> 
            
        </div>
    </div> </Flip>
    </form>
   
    </div> 
    <Zoom>
    <div class="container">
        
<div class="wrapper">
{/* <a href="https://www.facebook.com/" class="icon-container icon-container-facebook">
<i class="fab fa-facebook-f"></i>
<div class="icon-text icon-text-facebook">Facebook</div>
</a>
<a href="http://www.linkedin.com/in/raaj-mishra-0a5764210" class="icon-container icon-container-twitter">
<i class="fab fa-twitter"></i>
<div class="icon-text icon-text-twitter">Twitter</div>
</a>
<a href="#" class="icon-container icon-container-github">
<i class="fab fa-github"></i>
<div class="icon-text icon-text-github">Github</div>
</a>
<a href="https://www.instagram.com/sidmalhotra/?igshid=YmMyMTA2M2Y%3D" target="_blank" class="icon-container icon-container-instagram">
<i class="fab fa-instagram"></i>
<div class="icon-text icon-text-instagram">Instagram</div>
</a> */}
</div>
</div></Zoom>

<footer className='footer'>
    <div class="container ">
    <footer class="text-center text-lg-start mt-xl-5 pt-4">
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">OUR WORLD</h5>
          <ul class="list-unstyled mb-4">
            <li>
              <a href="/about" class="text-white">About us</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Assistance</h5>
          <ul class="list-unstyled">
            <li>
              <a href="/reachUs" class="text-white">Contact us</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Products</h5>

          <ul class="list-unstyled">
            <li>
              <a href="/service" class="text-white">Our Products</a>
            </li>
          </ul>
        </div>
        
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Home</h5>

          <ul class="list-unstyled">
            <li>
              <a href="/" class="text-white">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center p-3 border-top border-white text-white">
      © 2020 Copyright:
      <a class="text-white" href="https://asmainfotech.com/">asmainfotech.com</a>
    </div>
  </footer>
</div>
</footer>

    </>
  )
}

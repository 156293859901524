
import './Service.css'
import img1  from '../assets/DSC_0008.JPG'
import img2 from '../assets/DSC_0040.JPG'
import img3 from '../assets/DSC_0067.JPG'
import img4 from '../assets/DSC_0036.JPG'
import img5 from '../assets/DSC_0050.JPG'
import img6 from '../assets/DSC_0016.JPG'
import img7 from '../assets/DSC_0066.JPG'
import img8 from '../assets/DSC_0056.JPG'
import img9 from '../assets/DSC_0060.JPG'
import img10 from '../assets/Dewfixtd1.jpeg'
import img11 from '../assets/DSC_0062.JPG'
import img12 from '../assets/DSC_0067.JPG'
import img13 from '../assets/DewcofD5.jpg'
import img14 from '../assets/Cozudew2.jpg'
import img15 from '../assets/Livedew1.jpg'
import img16 from '../assets/DewnateF1.png'
import img17 from '../assets/Addoz.PNG'
import img18 from '../assets/DewnacAps.PNG'
import img19 from '../assets/KeyOnTab.PNG'
import img20 from '../assets/NeoPTab.PNG'
import img21 from '../assets/Mapple.PNG'
import img22 from '../assets/RappleSR.jpeg'
import img23 from '../assets/CozydewLpTab.PNG'
import img24 from '../assets/DewcofSypr.PNG'
import img25 from '../assets/GynobestSyrp.PNG'
import img26 from '../assets/KeyOnSus.jpeg'
import img27 from '../assets/Addaz.jpg'
import img28 from '../assets/Montizlc.jpg'
import img29 from '../assets/KeyOnInj1.jpeg'
import img30 from '../assets/MappleDS1.jpeg'
import img31 from '../assets/KeyOnEd2.jpeg'

import Flip  from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';

export default function Service() {
  return (<>
  <h1 class="ourteam">OUR PRODUCTS</h1>
 <Fade top>  <div className='section1'>
    <div className='section-1'>
        <img src={img27} alt="" width="80%"/>
<h3>ADD-AZ</h3>
<div>Composition : Azithromycin 250mg, Tablet is an antibiotic used to treat various types of bacterial infection of the respiratory tract, ear, nose, throat, lungs, skin, and eye in adults and children. It is also effective in typhoid fever and some sexually transmitted diseases like gonorrhea.
</div>
</div>
<div className='section-2'>
        <img src={img28} alt="" width="80%"/>
<h3>MONTIZ-LC</h3>
<div>Composition  : Levocetirizine 5mg , Montelukast 10mg. This is used in the treatment of allergic symptoms such as runny hope, stuffy hope, sheezing, itching, swelling, watery eyes and Congestion of staffiness, It also reduces inflammation in the airways and make breathing easter.</div>
</div>
{/* <div className='section-2'>
        <img src={img3} alt="" width="80%"/>
<h3>MAPPLE-DV</h3>
<div>Add here actual details this is test content. Add here actual details this is test content. Add here actual details this is test content. Add here actual details this is test content.</div>
</div> */}
<div className='section-2'>
     <img src={img22} alt="" width="80%"/>
<h2>Rapple-SR</h2>
<div>Composition : Rabeprazole Sodium 20mg,  Domperidone 30mg. This is a combination medicine used to treat gastro esophageal reflux disease (Acid reflux) and peptic ulcer disease by relieving the symptoms of acidity such as heartburn, stomach pain or irritation it also neutralized the acid and promote easy passage of gas to reduce stomach discomfort.
</div>
</div>
<div className='section-2'>
        <img src={img4} alt="" width="80%"/>
<h3>STREAM-MD</h3>
<div>Composition : Prochlorperazine 5mg. This is used in the treatment of vertigo (dizziness) due to ear problems (Meniere’s syndrome), nausea, vomiting and migraine caused by various conditions. Additionally this medicine is used in mental illness such as short-term anxiety.</div>
</div>

</div>
</Fade>
<Fade bottom> <div className='section2'>
 <div className='section-1'>
     <img src={img5} alt="" width="80%"/>
<h2>DEWDOL-P</h2>
<div> Composition : Aceclofenac 100mg , Paracetamol 325mg. This tablet is a pain-relieving medicine. It is used to reduce pain and inflammation in conditions like rheumatoid arthritis, ankylosing spondylitis and osteoarthritis. It may also be used to relieve muscle pain, back pain, toothache or pain in the ear and throat.
</div>
</div>
<div className='section-2'>
     <img src={img6} alt="" width="80%"/>
<h2>MAPPLE-MF</h2>
<div>Composition : Mefenamic Acid 250mg , Dicyclomine Hcl 10mg. This is a prescription medicine that helps to provide symptomatic relief from menstrual (period-related) pain and cramps. It is also used to treat abdominal pain by relieving spasms of the muscle in the stomach and intestines.
</div>
</div>
<div className='section-2'>
     <img src={img29} alt="" width="80%"/>
<h2>KEY-ON</h2>
<div>Composition : Key-on injection in a man-made form of vitamin B12. It is used to treat deficiency of vitamin B12 in the body. Vitamin B12 is important for growth cell reproduction blood formation and protein and tissue synthesis. It also helps to treat anemia fatigue and numbness or tingling in the hands and feet.
</div>
</div>
{/* <div className='section-2'>
     <img src={img8} alt="" width="80%"/>
<h2>DEWFIX-CV-625</h2>
<div>Composition : AMOXYCILLIN-500MG + CLAVULANIC ACID-125MG.
</div>
</div> */}
<div className='section-2'>
     <img src={img23} alt="" width="70%"/>
<h2>Cozydew-LP</h2>
<div>Composition :  Levocetirizine 2.5mg , Phenylephrine 10mg. This Tablet is a combination medicine used in the treatment of allergy symptoms like runny nose, sneezing, throat irritation, watery eyes and congestion of stuffiness in the nose.
</div>
</div>

</div>
</Fade>
<Fade bottom> <div className='section2'>
 <div className='section-1'>
     <img src={img9} alt="" width="80%"/>
<h2>COZYDEW</h2>
<div>Composition : CPM 4MG , Phenylepherine HCLmg , Paracetamol 325mg. This Tablet is a medicine used in the treatment of common cold symptoms. It provides relief from symptoms such as headache, sore throat. runny nose, muscular pain, and fever.
</div>
</div>
<div className='section-2'>
     <img src={img10} alt="" width="80%"/>
<h2>DEWFIX-DT</h2>
<div>Composition : Cefixime 200mg, This Tablet is an antibiotic belonging that is used to treat variety of bacterial Infections. It is effective in infections of the respiratory tract (eg. Pneumonia), urinary tract, ear, nasal sinus, throat and some sexually transmitted diseases.
</div>
</div>
<div className='section-2'>
     <img src={img11} alt="" width="80%"/>
<h2>DEWNAC-DPS</h2>
<div>composition  : Diclofenac  Potassium 50mg , Paracetamol 325mg , Serratiopeptidase 10mg, this is a pain–relieving medicine. It helps in relieving moderate pain and reducing fever.it is used in various conditions such as muscle ache , back pain ,menstrual cramps and toothache.
</div>
</div>
<div className='section-2'>
     <img src={img12} alt="" width="80%"/>
<h2>MAPPLE-DV</h2>
<div>Composition : Drotaverine 80mg , Mefenamic Acid 250mg. This is used in the treatment of abdominal pain. It works effectively to reduce abdominal pain, bloating, discomfort and cramps by relaxing the muscles of the stomach and gut. It also blocks certain chemical messenger that cause pain and discomfort. 
</div>
</div>

</div>
</Fade>

<Fade bottom> <div className='section2'>
 <div className='section-1'>
     <img src={img17} alt="" width="80%"/>
<h2>ADD-OZ</h2>
<div>  Composition : Ofloxacin 200mg , Orniddazole 500mg. This Tablet is a combination of two antibiotics. It is used in the treatment of bacterial and parasitic infections. It effectively treats a wide range of bacterial infections that may occur in the teeth, lungs, gastrointestinal infections, urinary and genital tract.
</div>
</div>
<div className='section-2'>
     <img src={img18} alt="" width="80%"/>
<h2>Dewnac-APS</h2>
<div>Composition : Aceclofenac 100mg , Paracetamol 325mg , Serratiopeptidase 15mg, it is used to relieve pain and swelling in various conditions like muscle pain, joint pain and postoperative pain. Thus it effectively alleviates pain and in flammation in condition like rheumatoid arthritis, ankylosing spondylitis and osteo arthritis.
</div>
</div>
<div className='section-2'>
     <img src={img19} alt="" width="80%"/>
<h2>Key-on Tablets</h2>
<div>Composition : Methylcobalamin 1500 mcg , ALA 100mg , Vitamin B6 20mg , Folic Acid 5mg, Key on is used to treat vitamin and mineral deficiencies. It insures the proper growth and functioning of the body. It is also used to treat herve pain in diabetic patients.
</div>
</div>
<div className='section-2'>
     <img src={img20} alt="" width="80%"/>
<h2>NEO-P Tablets</h2>
<div> Composition : Nimesulide 100mg , Paracetamol 325mg. This tablet is a combination medicine that helps in relieving pain. It is used to relieve pain and inflammation in conditions like rheumatoid arthritis, ankylosing spondylitis and osteoarthritis. It is also used to relieve fever, muscle pain, back pain, toothache or pain in the ear and throat.
</div>
</div>

</div>
</Fade>

<Fade bottom> <div className='section2'>
 
<div className='section-1'>
     <img src={img13} alt="" width="60%"/>
<h2>Dewcof-D</h2>
<div>Composition : Dextromethorphan Hydro Bromide 10mg , Phenylephriine 5mg , CPM 2MG. This is a combination medicine used in the treatment of dry cough. It relieves allergic symptoms such as sneezing, runny nose, watery eyes It also provides relief from congestion or stuffiness in the nose.
</div>
</div>

<div className='section-2'>
     <img src={img26} alt="" width="50%"/>
<h2>key-on (Susp)</h2>
<div>composition : Multivitamin, Multimineral & Antioxidant, Keyon is a dietary supplement enriched with minerals, multivitamins and antioxidants to help maintain optimum health. This Syrup replenish the nutrient reserves of the body, which aid in the proper functioning of the heart, gut, and nervous system. 
     {/* The presence of natural antioxidants in this syrup combats stress, fatigue, weakness and exhaustion, retards degenerative processes of ageing and improves the body's resistance to infections. */}
</div>
</div>

<div className='section-2'>
     <img src={img14} alt="" width="60%"/>
<h2>Cozydew</h2>
<div>  Composition : CPM 1MG , Phenylephrine HCL 2.5mg Paracetamol 125mg. Cozydew Syrup is commonly prescribed in children to treat symptoms such as runny nose, cough, sneezing watery eyes, sore throat, body ache and fever (allergic rhinitis) and other respiratory tract conditions.
</div>
</div>

<div className='section-2'>
     <img src={img16} alt="" width="70%"/>
<h2>Dewnate-F</h2>
<div>Composition : Doxylamine Succinate 10mg , Pyridoxine Hcl 10mg , Folic Acid 2.5mg, this tablet is a combination medicine used in the treatment of nausea or vomiting during pregnancy.
</div>
</div>
</div>
</Fade>

<Fade bottom> <div className='section2'>
<div className='section-1'>
     <img src={img21} alt="" width="60%"/>
<h2>Mapple</h2>
<div>  Composition :Paracetamol 125mg , Mefenamic Acid 50mg. Contains two active ingredients both belongs to a common class of medicines known as non-steroidal anti-inflammatory drugs (NSAIDs). It helps to lower body temperature (fever) and reduce pain and inflammation (redness and swelling ) both in infants and children.
</div>
</div>

<div className='section-2'>
     <img src={img15} alt="" width="70%"/>
<h2>Livedew-DS</h2>
<div>Composition : Bhringraj, Punarnava, Kutki, Sarpunkha, Kashi, Bhui amla, Giloy, Arjun, Nishoth, Rohitak, Helps with liver dysfunction & damage and provides prophylactic protection against drug induced hepatotoxicity.
</div>
</div>
<div className='section-2'>
     <img src={img24} alt="" width="60%"/>
<h2>Dewcof</h2>
<div>Composition :  Terbutaline 1.25mg ,Guaiphensin 50mg Ambroxol 15mg. Dewcof 100 ml Sugar free is used in the treatment of cough with mucus. It thins mucus in the nose, windpipe, and lungs making it easier to cough out. It also provides relief from runny those , sneezing , itching and watery eyes.
</div>
</div>
<div className='section-2'>
     <img src={img25} alt="" width="50%"/>
<h2>Gynobest</h2>
<div>Composition : Ashok, Lodra, Aswagandha, Nagarmotha, Vidarikand, Nagkesar, Amla, Haritiki, Crynobest help with leucorrhoea, menorrhagia ,dysmenorrhea ovulatory disturbances and helps in maintaining vaginal Ph.
</div>
</div>
</div>
</Fade>

<Fade bottom> <div className='section3'>
<div className='section-1'>
     <img src={img30} alt="" width="60%"/>
<h2>Mapple-DS</h2>
<div>  Composition : Paracetamol 250 mg + Mefinamic Acid 100mg .Mapple-DS contain two active ingredients both belongs to a common class of medicines known as non-steroidal anti- inflammatory drug (NSAID). It helps to lower body temperature (fever) and reduce pain and inflammation (redness and swelling) both in infants and children.
</div>
</div>

<div className='section-3'>
     <img src={img31} alt="" width="70%"/>
<h2>Key-on ED</h2>
<div>Composition : It contain the right balanced of carbohydrate and salt such as sodium , potassium ,magnesium, calcium and chloride. Sodium  maintain electrolyte balanced and quenches thirst, Potassium prevents muscle cramps, Magnesium maintains muscle and nerve function, Calcium builds bone strength and chloride maintain water balanced. Key-ONED assists in meeting individual recovery goal by replacing the electrolytes lots in sweat and refueling their energy. It can be useful for individuals. Who are tired due to work, are involved in sports and fitness activities, want to prevent a hangover and jetlag, and recovering from fever, illness or surgery.
</div>
</div>

</div>
</Fade>

<footer className='footer'>
    <div class="container ">
    <footer class="text-center text-lg-start mt-xl-5 pt-4">
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">OUR WORLD</h5>
          <ul class="list-unstyled mb-4">
            <li>
              <a href="/about" class="text-white">About us</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Assistance</h5>
          <ul class="list-unstyled">
            <li>
              <a href="/reachUs" class="text-white">Contact us</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Products</h5>

          <ul class="list-unstyled">
            <li>
              <a href="/service" class="text-white">Our Products</a>
            </li>
          </ul>
        </div>
        
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Home</h5>

          <ul class="list-unstyled">
            <li>
              <a href="/" class="text-white">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center p-3 border-top border-white text-white">
      © 2020 Copyright:
      <a class="text-white" href="https://asmainfotech.com/">asmainfotech.com</a>
    </div>
  </footer>
</div>
</footer>

</>
  )
}
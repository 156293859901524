import React from 'react'
import './Navbar.css'
import {Link} from 'react-router-dom'
export default function Navbar() {
  return (
    <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand text-danger" href="/">DewCare Biotech</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <Link class="nav-link active" to={"/"}>Home</Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link" to={"/about"}>About Us </Link>
          </li>
          <li class="nav-item dropdown">
            <Link class="nav-link" to={'/service'}>
              Our products
            </Link>
        </li>
        <li class="nav-item">
            <Link class="nav-link" to={"/reachus"}>Reach Us</Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link" to={"/swiper1"}> </Link>
          </li>
          
        </ul>
        <form class="d-flex">
        {/* <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/> */}
        {/* <button class="btn btn-outline-success" type="submit">Search</button> */}
      </form>
      </div>
    </div>
  </nav>
  )}
import React from 'react'
import './App.css';
import {Routes,Route} from "react-router-dom";
import Header from './component/Header';
import Navbar from './component/Navbar';
import Service from './component/Service';
import About from './component/About';
import Reachus from './component/Reachus';

import SimpleSlider from './component/Swiper1';



//Browser route
//Routes - Switch
//Route = path define




function App() {
  return (
  <>
  <div>
    <Navbar/>
    <Routes> 
      <Route exact path="/"  element={<Header/>} />
      <Route exact path="/service" element={<Service/>} />
      <Route exact path="/About" element={<About/>} />
      <Route exact path="/reachus" element={<Reachus/>} />
    </Routes>
  </div>
  </>
  );
}
export default App;

import React from 'react'
import './About.css'
import Swiper1 from './Swiper1'
import SimpleSlider from './Swiper1'
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';
import Zoom from 'react-reveal/Zoom';


export default function About() {
  return (<>
    <div className='about'>
        <h1 className='iir'>Who We Are</h1>
        <h4 className='iir'><strong>Dewcare Biotech</strong> is one of the renowned Medicine Company.</h4>
    </div>
     
        <div>
          {/* <p className='aboutlorem'>Home   Who we are </p> */}
          <h1 className='aboutlorem1'>About us</h1>
      <Slide left><p className='aboutlorem'><strong>DEW Care Biotech</strong> in Azad Nagar East, Delhi is known to satisfactorily cater to the demands of its customer base. The business came into existence in 2011 and has, since then, been a known name in its field. It stands located at 46/2, Azad Nagar East-110051.The business strives to make for a positive experience through its offerings.</p></Slide> 
      <Slide right><p className='aboutlorem'>Medicine Companies play a significant role in the pharmaceutical supply chain. They deal in the trading of medicines and other goods. Medicine Companies in Azad Nagar EastDelhi ensure that pharmacies, chemists, retailers, hospitals, and clinics consistently receive enough medications and other pharmaceutical items. To meet the growing demand for a variety of product lines, the Companies also keep stock of supplies from different pharmaceutical businesses.</p></Slide>
      <Slide left><p className='aboutlorem'>DEW Care Biotech is one of the renowned Medicine Company.</p></Slide>
      <Slide right><p className='aboutlorem'>Customer centricity is at the core of DEW Care Biotech in Azad Nagar East, Delhi and it is this belief that has led the business to build long-term relationships. Ensuring a positive customer experience, making available goods and/or services that are of top-notch quality is given prime importance.</p></Slide>
      <Slide left><p className='aboutlorem'>India’s leading B2B market place, we ensures engaging in business activities is a seamless process for small and medium enterprises as well as large businesses. In a wake to enable these businesses to reach their audience, this portal lets them showcase their offerings in terms of the products and/or services through a digital catalogue. This business has a wide range of product offerings and the product/catalogue list includes Medicine etc.</p></Slide>
        </div>
        {/* <Zoom> <Swiper1/></Zoom> */}
        
<footer className='footer'>
    <div class="container">
    <footer class="text-center text-lg-start mt-xl-5 pt-4">
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">OUR WORLD</h5>
          <ul class="list-unstyled mb-4">
            <li>
              <a href="/about" class="text-white">About us</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Assistance</h5>
          <ul class="list-unstyled">
            <li>
              <a href="/reachUs" class="text-white">Contact us</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Products</h5>

          <ul class="list-unstyled">
            <li>
              <a href="/service" class="text-white">Our Products</a>
            </li>
          </ul>
        </div>
        
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase mb-4 text-white">Home</h5>

          <ul class="list-unstyled">
            <li>
              <a href="/" class="text-white">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center p-3 border-top border-white text-white">
      © 2020 Copyright:
      <a class="text-white" href="https://asmainfotech.com/">asmainfotech.com</a>
    </div>
  </footer>
</div>
</footer>
        </>
       
  )
}

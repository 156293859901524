
import React, { Component } from "react";
import Slider from "react-slick";
import './swiper.css'
import img1  from '../assets/aws3.jpg'
import img2 from '../assets/cloud.jpg'
import img3 from '../assets/SERVICE.jpg'
import img4 from '../assets/pexels-pixabay-356040.jpg'

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };
    return (
      <div className="imgslide" >
        <h1 className="clients">our clients</h1>
        <Slider {...settings} >
          <div>
          <img src={img4} alt=""/>
          </div>
          <div>
          <img src={img4} alt=""/>
          </div>
          <div>
          <img src={img4} alt="" />
          </div>
          <div>
          <img src={img4} alt="" />
          </div>
          <div>
          <img src={img4} alt=""/>
          </div>
          {/* <div>
          <img src={img4} alt="" />
          </div> */}
        </Slider>
      </div>
    );
  }
}
